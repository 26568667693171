import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { auth } from "../../firebase";
import styles from "../../Login/login.module.css";
import { AiOutlineMail } from "react-icons/ai";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handlePasswordReset = (e) => {
    e.preventDefault();

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setSuccess(true);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
        setSuccess(false);
      });
  };

  useEffect(() => {
    if (error) {
      const toRef = setTimeout(() => {
        setError("");
        clearTimeout(toRef);
      }, 3000);
    }
  }, [error]);

  return (
    <div className="max-w-screen-xl w-full p-4 relative rounded-lg font-Poppins">
      <section className="flex flex-row justify-center">
        <div
          className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-white/80 absolute -top-10 right-0 md:right-2 m-1 font-bold hover:text-red-600  ease-linear duration-150 cursor-pointer"
          onClick={() => props.setPasswordState(false)}
        >
          <div className="hidden md:flex ">Close</div>
          <div>
            <MdClose size={25} />
          </div>
        </div>
      </section>

      <div className="py-8">
        <div className="text-white font-Poppins md:text-2xl text-xl font-semibold pb-3">
          Forgot Password
        </div>
        {success && (
          <p className="text-white font-Poppins text-xl">
            Password reset email sent successfully.
          </p>
        )}
        {error && (
          <div className="flex p-2">
            <div className="text-[#D1433A] text-base font-bold ml-2 -mt-0.5">
              {error}
            </div>
          </div>
        )}
        <div className={styles.loginDiv}>
          <AiOutlineMail
            style={{
              color: "#ffffff",
            }}
            className={styles.usericons}
          />
          <input
            type="email"
            className={styles.logininput}
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div>
          <button
            onClick={handlePasswordReset}
            className="flex flex-row items-center justify-center py-2 w-full mt-6 mb-3 rounded-lg bg-white/100 text-black font-medium  ease-linear duration-150"
          >
            Reset Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
