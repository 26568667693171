import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGeVn1VwiY4aD2dIks2o3XWluYsxTsfcc",
  authDomain: "alpha-nifty-dashboard.firebaseapp.com",
  projectId: "alpha-nifty-dashboard",
  storageBucket: "alpha-nifty-dashboard.appspot.com",
  messagingSenderId: "659777284427",
  appId: "1:659777284427:web:a052472da3aea24b2060ce",
  measurementId: "G-TVZWXMKHS1",
};

const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

const db = app.firebase.firestore();
const auth = app.firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
export { db, auth, provider };
