import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

const fetchUser = ({ queryKey }) => {
  const heroId = queryKey[1];
  return axios.get(
    `https://server.sypto.xyz/sypto/api/nifty-user/getUser/${heroId}`
  );
};

const fetchDetails = () => {
  return axios.get(`https://server.sypto.xyz/dashboard/get_alpha_index_data`);
};

const fetchOverviewData = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-clearmind/data/overview"
    // "http://localhost:5000/api/dashboard-clearmind/data/overview"
  );
};

const fetchOverviewDataHeading = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-clearmind/data/overview/heading"
    // "http://localhost:5000/api/dashboard-clearmind/data/overview/heading"
  );
};

const fetchYOYDataHeading = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-clearmind/data/year-on-year/heading"
    // "http://localhost:5000/api/dashboard-clearmind/data/year-on-year/heading"
  );
};
const fetchYearOnYearData = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-clearmind/data/year-on-year/yoy"
      // "http://localhost:5000/api/dashboard-clearmind/data/year-on-year/yoy"
  );
};

const fetchAlpha50Data = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-alpha-vs-nifty-charts/alpha-50"
    // "http://localhost:5000/api/dashboard-alpha-vs-nifty-charts/alpha-50"
  );
};

const fetchAlpha100Data = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-alpha-vs-nifty-charts/alpha-100"
    // "http://localhost:5000/api/dashboard-alpha-vs-nifty-charts/alpha-100"
  );
};

const fetchAlpha100AllData = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-alpha-vs-nifty-charts/alpha-100/all"
    // "http://localhost:5000/api/dashboard-alpha-vs-nifty-charts/alpha-100/all"
  );
};

const fetchAlpha200Data = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-alpha-vs-nifty-charts/alpha-200"
    // "http://localhost:5000/api/dashboard-alpha-vs-nifty-charts/alpha-200"
  );
};

const fetchAlpha500Data = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/dashboard-alpha-vs-nifty-charts/alpha-500"
    // "http://localhost:5000/api/dashboard-alpha-vs-nifty-charts/alpha-500"
  );
};

const fetchAlphaSPData = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/alpha-vs-nifty-charts/alpha-s&p"
  );
};
const fetchAlphaNasdaqData = () => {
  return axios.get(
    "https://server.sypto.xyz/sypto/api/alpha-vs-nifty-charts/alpha-nasdaq"
  );
};

export const CurrentUserData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["userHero", heroId], fetchUser, {
    initialData: () => {
      const data = queryClient
        .getQueryData("user-heroes")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseTradeHistoryData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["tradeHistory", hero], fetchDetails, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseOverviewData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["overviewData", hero], fetchOverviewData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseOverviewDataHeading = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["overviewDataHeading", hero], fetchOverviewDataHeading, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseYOYDataHeading = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["yoyDataHeading", hero], fetchYOYDataHeading, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseYearOnYearData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["yearOnYearData", hero], fetchYearOnYearData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseAlpha50Data = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["alpha50Data", hero], fetchAlpha50Data, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseAlpha100Data = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["alpha100ChartData", hero], fetchAlpha100Data, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};
export const UseAlpha100AllData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["alpha100AllData", hero], fetchAlpha100AllData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseAlpha200Data = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["alpha200Data", hero], fetchAlpha200Data, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseAlpha500Data = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["alpha500Data", hero], fetchAlpha500Data, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseAlphaSPData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["alphaSPData", hero], fetchAlphaSPData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseAlphaNasdaqData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["alphaNasdaqChartData", hero], fetchAlphaNasdaqData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};
