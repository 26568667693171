import React from "react";
import { Line } from "react-chartjs-2";
import Moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  // responsive: true,
  maintainAspectRatio: false,
  animations: {
    tension: {
      duration: 2000,
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItems, data) {
        return "$" + tooltipItems.y.toString();
      },
    },
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      position: "top",
      labels: {
        boxWidth: 10,
        boxHeight: 4,
        textAlign: "center",
        color: "#ffffff",
        fontSize: 20,
        marginBottom: 5,
      },
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: true,
        drawBorder: true, // Display X axis border
        color: "rgba(255, 255, 255, 0.1)",
      },
    },
    y: {
      grid: {
        display: true,
        drawBorder: true, // Display Y axis border
        color: "rgba(255, 255, 255, 0.1)",
      },
      title: {
        display: true,
      },
    },
  },
};

const ChartPlotting = ({ chartData, titleOne, titleTwo }) => {
  const data = chartData;

  const DateLable = (data) => {
    let n = data && data.length;
    let m = 3;
    let Date = [];
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < m; j++) {
        if (i > 0 && j === 0) {
          const one = Moment(data[i][j], "DD-MM-YYYY HH:mm:ss A").format(
            "DD MMM YY"
          );
          Date.push(one);
        }
      }
    }
    return Date;
  };
  const alphaData = (data) => {
    let n = data && data.length;
    let m = 3;
    let Bit = [];
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < m; j++) {
        if (i > 0 && j === 1) {
          Bit.push(data[i][j]);
        }
      }
    }

    return Bit;
  };
  const niftyData = (data) => {
    let n = data.length;
    let m = 3;
    let syp = [];
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < m; j++) {
        if (i > 0 && j === 2) {
          syp.push(data[i][j]);
        }
      }
    }
    return syp;
  };

  const data1 = {
    labels: DateLable(data),
    datasets: [
      {
        label: titleOne,
        data: alphaData(data),
        borderColor: "#18B13F",
        backgroundColor: "#18B13F",
        tension: 0.4,
        borderWidth: 3,
        radius: 0,
      },
      {
        label: titleTwo,
        data: niftyData(data),
        borderColor: "#0000FF",
        backgroundColor: "#0000FF",
        tension: 0.4,
        borderWidth: 3,
        radius: 0,
      },
    ],
  };

  return (
    <section className="mx-auto" style={{ height: "100%" }}>
      <section className="mr-3 my-3 pr-4">
        {data === "undefined" && (
          <div
            className="d-flex  justify-content-center align-items-center"
            style={{ height: "10vh" }}
          >
            <div
              style={{ fontSize: "30px" }}
              className="d-block fw-bold text-center color-black"
            ></div>
          </div>
        )}
        <div style={{ position: "relative", height: "42vh", width: "100%" }}>
          <Line data={data1} options={options} />
        </div>
      </section>
    </section>
  );
};

export default ChartPlotting;
