import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Navbar from "../Navbar/Navbar";
import styles from "../../Home/HomePage.module.css";
import TableComponent from "./TableComponent";
import { auth } from "../../firebase";
import {
  CurrentUserData,
  UseTradeHistoryData,
  UseOverviewData,
  UseOverviewDataHeading,
  UseYearOnYearData,
  UseAlpha200Data,
} from "../../utils/data";
import DashBoard from "../../Home/DashBoard";
import OutPerformaceAndChart from "./OutPerformaceAndChartt";

const AlphaTwoHundred = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const { data: userHero } = CurrentUserData(userEmail);
  const userDetails = userHero && userHero.data.User;

  const { data: tradeHistory } = UseTradeHistoryData();

  const { data: overviewData } = UseOverviewData();
  const { data: overviewDataHeading } = UseOverviewDataHeading();
  const { data: yearOnYearData } = UseYearOnYearData();

  const newOverViewDataHeading =
    overviewDataHeading && overviewDataHeading.data.values;
  const newOverViewData = overviewData && overviewData.data.values;
  const newYearOnYearData = yearOnYearData && yearOnYearData.data.values;

  const newData1 = tradeHistory && tradeHistory.data.mappings;
  const result =
    newData1 &&
    newData1.filter((item) => item.frontend_table_name === "Alpha 200");
  const ssTop150DataTablename = result && result[0]["table_name"];

  const ssTop150Data =
    tradeHistory && tradeHistory.data.data[ssTop150DataTablename];

  const { data: alpha200Data } = UseAlpha200Data();

  //alpha 200 start
  const alphaTwoHData = alpha200Data && alpha200Data.data.values;
  const alphaTwoHPerformceAccountValue =
    alphaTwoHData && alphaTwoHData.map((item) => [item[0], item[1], item[2]]);

  const alpha200OutPerformance =
    alphaTwoHData && alphaTwoHData.map((item) => [item[10]]);

  const alpha200OutPerformanceValue =
    alpha200OutPerformance &&
    alpha200OutPerformance.flat().filter((value) => value !== undefined);

  const alphaTwoHPerformceAccountValueData =
    alphaTwoHPerformceAccountValue &&
    alphaTwoHPerformceAccountValue.map((innerArray, index) =>
      innerArray.map((value, columnIndex) => {
        // Skip the first column
        if (columnIndex === 0 || typeof value !== "string") {
          return value;
        }

        // Remove the leading rupee sign and commas, then convert to a number
        return parseFloat(value.replace(/^₹|,/g, ""));
      })
    );

  const new200Data =
    alphaTwoHData && alphaTwoHData.map((item) => [item[0], item[5], item[6]]);
  const data200Array = new200Data ? new200Data : [];

  const alpha200MonthlyReturns = [data200Array[0]]; // Add the header to the new array

  // Create a Set to keep track of unique months
  const unique200MonthsSet = new Set();

  // Iterate through the original array starting from index 1 (skipping the header)
  for (let i = 1; i < data200Array.length; i++) {
    const currentDate = data200Array[i][0];

    // Check if currentDate is defined and is a string

    if (currentDate && typeof currentDate === "string") {
      const [month, year] = currentDate.split("/").map(Number);
      const currentMonthKey = `${month}/${year}`; // Extract the month and year part

      // Check if the current month is not already in the uniqueMonthsSet and the data is not empty or undefined
      if (
        !unique200MonthsSet.has(currentMonthKey) &&
        data200Array[i].every((value) => value !== undefined && value !== "")
      ) {
        alpha200MonthlyReturns.push(data200Array[i]);
        unique200MonthsSet.add(currentMonthKey);
      }
    }
  }

  const alphaCumulative = alphaTwoHData && alphaTwoHData[1][9];
  const niftyCumulative = alphaTwoHData && alphaTwoHData[5][9];

  return (
    <div>
      {userDetails && userDetails.account_access === "admin" ? (
        <DashBoard />
      ) : (
        <div>
          <Navbar />

          <div className="mt-20">
            <div className="bg-[#14141C] flex flex-row py-8 px-12">
              <div className="w-[50%] px-8">
                <div className="text-2xl text-white font-montserrat">
                  Alpha 200
                </div>
                <div className="text-lg text-[#ffffff]/60 mt-2 font-poppins">
                  Unleashing the Power of Momentum Investing
                </div>
              </div>
              <div className="border-l-[1px] border-[#ffffff]/30 px-8">
                <div className="text-lg text-white font-montserrat font-semibold">
                  Investment Objective
                </div>
                <div className="text-base text-[#ffffff]/60 mt-2 font-poppins">
                  Alpha 200 is a concentrated momentum portfolio that undergoes
                  weekly rebalancing. Our quant-based strategy scans the top
                  0-150 companies in NSE and meticulously selects a portfolio of
                  10 promising stocks for your investment. The weekly
                  rebalancing ensures that underperforming stocks are replaced,
                  keeping your investments aligned with the ever-changing market
                  dynamics.
                </div>
              </div>
            </div>
            <div className={`px-4 pt-4 pb-16 flex flex-row justify-between`}>
              <div className="w-[30%] pl-4">
                {ssTop150Data && ssTop150Data ? (
                  <TableComponent watchlist={ssTop150Data && ssTop150Data} />
                ) : null}
                <div className="w-full">
                  <div className="font-montserrat border-[1px] border-b-0 border-[#ffffff]/20 bg-[#14141C]  w-[90%] text-white mt-3 py-[10px] pl-3 font-semibold">
                    Statistics
                  </div>

                  <table className={`${styles.tableUser2}`}>
                    <thead>
                      {newOverViewDataHeading &&
                        newOverViewDataHeading.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <th scope="col"></th>
                              <th scope="col">{ele[5]}</th>
                              <th scope="col">{ele[2]}</th>
                              <th scope="col">{ele[6]}</th>
                            </tr>
                          );
                        })}
                    </thead>

                    <tbody>
                      {newOverViewData &&
                        newOverViewData.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td>{ele[0]}</td>
                              <td className="text-right">{ele[5]}</td>
                              <td className="text-right">{ele[2]}</td>
                              <td className="text-right">{ele[6]}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-[70%]">
                <OutPerformaceAndChart
                  monthlyReturns={
                    alpha200MonthlyReturns && alpha200MonthlyReturns
                  }
                  performaceData={
                    alphaTwoHPerformceAccountValueData &&
                    alphaTwoHPerformceAccountValueData
                  }
                  outPerformance={alpha200OutPerformanceValue}
                  titleOne="Alpha 200"
                  titleTwo="Nifty 200"
                  tabName="alpha-200"
                  alphaCumulative={alphaCumulative}
                  niftyCumulative={niftyCumulative}
                />
                <div className="w-full text-white text-2xl font-bold py-4 pl-3  font-montserrat">
                  Backtested Year-on-Year Performance
                </div>

                <table className={`${styles.tableUser1}`}>
                  <tbody>
                    <tr>
                      <th
                        scope="col"
                        className="bg-[#14141C] font-montserrat font-semibold"
                      >
                        Year
                      </th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td
                              style={{
                                textAlign: "right",
                              }}
                              key={i}
                              className="bg-[#14141C] font-montserrat font-semibold"
                            >
                              {ele[0]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th scope="col">nifty</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i}>
                              {ele[1]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th scope="col">nifty 200</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i}>
                              {ele[5]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th scope="col">alpha 200</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td
                              className={
                                parseFloat(ele[6]) > parseFloat(ele[5])
                                  ? styles.greenColor
                                  : styles.redColor
                              }
                              key={i}
                            >
                              {ele[6]}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
                <table className={`${styles.tableUser3} mt-8`}>
                  <tbody>
                    <tr>
                      <th
                        scope="col"
                        className="bg-[#14141C] font-montserrat font-semibold"
                      >
                        Year
                      </th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td
                              style={{
                                textAlign: "right",
                              }}
                              key={i + 10}
                              className="bg-[#14141C] font-montserrat font-semibold"
                            >
                              {ele[0]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th scope="col">nifty</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i + 10}>
                              {ele[1]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th scope="col">nifty 200</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i + 10}>
                              {ele[5]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th scope="col">alpha 200</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td
                              className={
                                parseFloat(ele[6]) > parseFloat(ele[5])
                                  ? styles.greenColor
                                  : styles.redColor
                              }
                              key={i + 10}
                            >
                              {ele[6]}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlphaTwoHundred;
