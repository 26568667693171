import React from "react";
import ShowChartTable from "../Charts/SHowChartTable";
import ChartPlotting from "../Charts/ChartPlotting";
// import { RiLineChartFill } from "react-icons/ri";
// import { BsTable } from "react-icons/bs";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import DownloadReport from "../../components/download-report";

const OutPerformaceAndChart = ({
  monthlyReturns,
  performaceData,
  titleOne,
  titleTwo,
  outPerformance,
  tabName,
  alphaCumulative,
  niftyCumulative,
}) => {
  return (
    <div className="mt-6 mb-12">
      <div className="flex flex-row justify-between">
        <div className="flex justify-between w-full px-4">
          <div className="text-white text-2xl font-bold flex flex-row items-center font-montserrat">
            {parseFloat(outPerformance && outPerformance[1]) > 0 ? (
              <FaLongArrowAltUp />
            ) : (
              <FaLongArrowAltDown />
            )}
            OutPerformance :{" "}
            {parseFloat(outPerformance && outPerformance[1]) > 0 ? (
              <span
                className={
                  parseFloat(outPerformance && outPerformance[1]) > 0
                    ? "text-[#18b13f] ml-2"
                    : "text-[#ed4731] ml-2"
                }
              >
                + {outPerformance && outPerformance[1]}
              </span>
            ) : (
              <span
                className={
                  parseFloat(outPerformance && outPerformance[1]) > 0
                    ? "text-[#18b13f] ml-2"
                    : "text-[#ed4731] ml-2"
                }
              >
                {outPerformance && outPerformance[1]}
              </span>
            )}
          </div>
          <DownloadReport tabName={tabName} />
        </div>
      </div>
      <ChartPlotting
        chartData={performaceData ? performaceData : []}
        titleOne={titleOne}
        titleTwo={titleTwo}
      />

      <ShowChartTable
        monthlyReturns={monthlyReturns && monthlyReturns}
        titleOne={titleOne}
        titleTwo={titleTwo}
        alphaCumulative={alphaCumulative && alphaCumulative}
        niftyCumulative={niftyCumulative && niftyCumulative}
      />
    </div>
  );
};

export default OutPerformaceAndChart;
