import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Navbar from "../Navbar/Navbar";
import styles from "../../Home/HomePage.module.css";
import { auth } from "../../firebase";
import {
  CurrentUserData,
  UseOverviewData,
  UseOverviewDataHeading,
  UseYearOnYearData,
  UseYOYDataHeading,
} from "../../utils/data";
import DashBoard from "../../Home/DashBoard";

const style = {
  watchlistHeading:
    "text-white text-xl text-center font-montserrat font-semibold",
  accountHeading: "text-white text-xl pl-10 font-bold",
  tableDiv: "flex flex-wrap pl-10 pt-4",
  tableDivHeading:
    "text-white py-2 px-4 border-1 border-[#14141C]/90 text-white px-4",
  tableDivHeadingTwo:
    "text-white py-2 px-4 border-l-1 border-[#14141C]/90 text-white px-4",
};
const UserDashboard = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const { data: userHero } = CurrentUserData(userEmail);
  const userDetails = userHero && userHero.data.User;

  const { data: overviewData } = UseOverviewData();
  const { data: overviewDataHeading } = UseOverviewDataHeading();
  const { data: yearOnYearData } = UseYearOnYearData();
  const { data: yoyDataHeading } = UseYOYDataHeading();

  const newOverViewDataHeading =
    overviewDataHeading && overviewDataHeading.data.values;
  const newOverViewData = overviewData && overviewData.data.values;
  const newYearOnYearData = yearOnYearData && yearOnYearData.data.values;

  const newYoyDataHeading = yoyDataHeading && yoyDataHeading.data.values;

  return (
    <div>
      {userDetails && userDetails.account_access === "admin" ? (
        <DashBoard />
      ) : (
        <div>
          <Navbar />

          <div
            className={`${styles.heroBackground} pt-28 pb-16 justify-center`}
          >
            {newOverViewData && newOverViewData ? (
              <div className="fade_animation">
                {" "}
                <div className="flex-shrink-0">
                  <div className={style.watchlistHeading}> Statistics</div>
                  <div className="flex flex-row justify-center px-2 mt-6">
                    <div className="flex flex-row justify-center">
                      <table className={`${styles.table1}`}>
                        <thead>
                          {newOverViewDataHeading &&
                            newOverViewDataHeading.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <th scope="col" className="font-bold">
                                    Parameter
                                  </th>

                                  <th className="text-right">{ele[2]}</th>
                                  <th className="text-right">{ele[1]}</th>
                                </tr>
                              );
                            })}
                        </thead>
                        <tbody>
                          {newOverViewData &&
                            newOverViewData.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <td className="font-bold">{ele[0]}</td>
                                  <td className="text-right">{ele[2]}</td>
                                  <td
                                    className={
                                      parseFloat(ele[1]) > parseFloat(ele[2])
                                        ? `${styles.greenColor1}`
                                        : `${styles.redColor1}`
                                    }
                                  >
                                    {" "}
                                    {ele[1]}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <table className={`${styles.table1} ${styles.ml10}`}>
                        <thead>
                          {newOverViewDataHeading &&
                            newOverViewDataHeading.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <th scope="col" className="text-right">
                                    {ele[4]}
                                  </th>
                                  <th className="text-right">{ele[3]}</th>
                                </tr>
                              );
                            })}
                        </thead>
                        <tbody>
                          {newOverViewData &&
                            newOverViewData.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-right">{ele[4]}</td>
                                  <td
                                    className={
                                      parseFloat(ele[3]) > parseFloat(ele[4])
                                        ? `${styles.greenColor1}`
                                        : `${styles.redColor1}`
                                    }
                                  >
                                    {ele[3]}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <table className={`${styles.table1} ${styles.ml10}`}>
                        <thead>
                          {newOverViewDataHeading &&
                            newOverViewDataHeading.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <th className="text-right">{ele[6]}</th>
                                  <th className="text-right">{ele[5]}</th>
                                </tr>
                              );
                            })}
                        </thead>
                        <tbody>
                          {newOverViewData &&
                            newOverViewData.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-right">{ele[6]}</td>
                                  <td
                                    className={
                                      parseFloat(ele[5]) > parseFloat(ele[6])
                                        ? `${styles.greenColor1}`
                                        : `${styles.redColor1}`
                                    }
                                  >
                                    {ele[5]}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <table className={`${styles.table1} ${styles.ml10}`}>
                        <thead>
                          {newOverViewDataHeading &&
                            newOverViewDataHeading.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <th className="text-right">{ele[8]}</th>
                                  <th className="text-right">{ele[7]}</th>
                                </tr>
                              );
                            })}
                        </thead>
                        <tbody>
                          {newOverViewData &&
                            newOverViewData.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-right">{ele[8]}</td>
                                  <td
                                    className={
                                      parseFloat(ele[7]) > parseFloat(ele[8])
                                        ? `${styles.greenColor1}`
                                        : `${styles.redColor1}`
                                    }
                                  >
                                    {ele[7]}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <table className={`${styles.table1} ${styles.ml10}`}>
                        <thead>
                          {newOverViewDataHeading &&
                            newOverViewDataHeading.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <th className="text-right">{ele[10]}</th>
                                  <th className="text-right">{ele[9]}</th>
                                </tr>
                              );
                            })}
                        </thead>
                        <tbody>
                          {newOverViewData &&
                            newOverViewData.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-right">{ele[10]}</td>
                                  <td
                                    className={
                                      parseFloat(ele[9]) > parseFloat(ele[10])
                                        ? `${styles.greenColor1}`
                                        : `${styles.redColor1}`
                                    }
                                  >
                                    {ele[9]}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <table className={`${styles.table1} ${styles.ml10}`}>
                        <thead>
                          {newOverViewDataHeading &&
                            newOverViewDataHeading.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <th className="text-right">{ele[12]}</th>
                                  <th className="text-right">{ele[11]}</th>
                                </tr>
                              );
                            })}
                        </thead>
                        <tbody>
                          {newOverViewData &&
                            newOverViewData.map((ele, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-right">{ele[12]}</td>
                                  <td
                                    className={
                                      parseFloat(ele[11]) > parseFloat(ele[12])
                                        ? `${styles.greenColor1}`
                                        : `${styles.redColor1}`
                                    }
                                  >
                                    {ele[11]}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="flex-shrink-0 mt-12">
                  <div className={style.watchlistHeading}>
                    {" "}
                    Backtested Year-on-Year Performance
                  </div>
                  <div className="flex flex-row justify-center px-2 mt-4">
                    <table className={`${styles.table1}`}>
                      <thead>
                        {newYoyDataHeading &&
                          newYoyDataHeading.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <th scope="col"></th>
                                <th className="text-right">{ele[1]}</th>
                                <th className="text-right">{ele[2]}</th>
                              </tr>
                            );
                          })}
                      </thead>
                      <tbody>
                        {newYearOnYearData &&
                          newYearOnYearData.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <td className="font-bold text-right">
                                  {ele[0]}
                                </td>
                                <td className="text-right">{ele[1]}</td>
                                <td
                                  className={
                                    parseFloat(ele[2]) > parseFloat(ele[1])
                                      ? styles.greenColor1
                                      : styles.redColor1
                                  }
                                >
                                  {ele[2]}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <table className={`${styles.table1} ${styles.ml10}`}>
                      <thead>
                        {newYoyDataHeading &&
                          newYoyDataHeading.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <th className="text-right">{ele[3]}</th>
                                <th className="text-right">{ele[4]}</th>
                              </tr>
                            );
                          })}
                      </thead>
                      <tbody>
                        {newYearOnYearData &&
                          newYearOnYearData.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-right">{ele[3]}</td>
                                <td
                                  className={
                                    parseFloat(ele[4]) > parseFloat(ele[3])
                                      ? styles.greenColor1
                                      : styles.redColor1
                                  }
                                >
                                  {ele[4]}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <table className={`${styles.table1} ${styles.ml10}`}>
                      <thead>
                        {newYoyDataHeading &&
                          newYoyDataHeading.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <th className="text-right">{ele[5]}</th>
                                <th className="text-right">{ele[6]}</th>
                              </tr>
                            );
                          })}
                      </thead>
                      <tbody>
                        {newYearOnYearData &&
                          newYearOnYearData.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-right">{ele[5]}</td>
                                <td
                                  className={
                                    parseFloat(ele[6]) > parseFloat(ele[5])
                                      ? styles.greenColor1
                                      : styles.redColor1
                                  }
                                >
                                  {ele[6]}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    <table className={`${styles.table1} ${styles.ml10}`}>
                      <thead>
                        {newYoyDataHeading &&
                          newYoyDataHeading.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <th className="text-right">{ele[7]}</th>
                                <th className="text-right">{ele[8]}</th>
                              </tr>
                            );
                          })}
                      </thead>
                      <tbody>
                        {newYearOnYearData &&
                          newYearOnYearData.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-right">{ele[7]}</td>
                                <td
                                  className={
                                    parseFloat(ele[8]) > parseFloat(ele[7])
                                      ? styles.greenColor1
                                      : styles.redColor1
                                  }
                                >
                                  {ele[8]}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    <table className={`${styles.table1} ${styles.ml10}`}>
                      <thead>
                        {newYoyDataHeading &&
                          newYoyDataHeading.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <th className="text-right">{ele[10]}</th>
                                <th className="text-right">{ele[11]}</th>
                              </tr>
                            );
                          })}
                      </thead>
                      <tbody>
                        {newYearOnYearData &&
                          newYearOnYearData.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-right">{ele[10]}</td>
                                <td
                                  className={
                                    parseFloat(ele[11]) > parseFloat(ele[10])
                                      ? styles.greenColor1
                                      : styles.redColor1
                                  }
                                >
                                  {ele[11]}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <table className={`${styles.table1} ${styles.ml10}`}>
                      <thead>
                        {newYoyDataHeading &&
                          newYoyDataHeading.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <th className="text-right">{ele[12]}</th>
                                <th className="text-right">{ele[13]}</th>
                              </tr>
                            );
                          })}
                      </thead>
                      <tbody>
                        {newYearOnYearData &&
                          newYearOnYearData.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-right">{ele[12]}</td>
                                <td
                                  className={
                                    parseFloat(ele[13]) > parseFloat(ele[12])
                                      ? styles.greenColor1
                                      : styles.redColor1
                                  }
                                >
                                  {ele[13]}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
