import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Navbar from "../Navbar/Navbar";
import styles from "../../Home/HomePage.module.css";
import TableComponent from "./TableComponent";
import { auth } from "../../firebase";
import {
  CurrentUserData,
  UseTradeHistoryData,
  UseOverviewData,
  UseOverviewDataHeading,
  UseYearOnYearData,
  UseAlpha50Data,
} from "../../utils/data";
import DashBoard from "../../Home/DashBoard";
import OutPerformaceAndChart from "./OutPerformaceAndChartt";

const AlphaFifty = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const { data: userHero } = CurrentUserData(userEmail);
  const userDetails = userHero && userHero.data.User;

  const { data: tradeHistory } = UseTradeHistoryData();

  const { data: overviewData } = UseOverviewData();
  const { data: overviewDataHeading } = UseOverviewDataHeading();
  const { data: yearOnYearData } = UseYearOnYearData();

  const newOverViewDataHeading =
    overviewDataHeading && overviewDataHeading.data.values;
  const newOverViewData = overviewData && overviewData.data.values;
  const newYearOnYearData = yearOnYearData && yearOnYearData.data.values;

  const newData1 = tradeHistory && tradeHistory.data.mappings;
  const result =
    newData1 &&
    newData1.filter((item) => item.frontend_table_name === "Alpha 50");
  const ssNiftyTablename = result && result[0]["table_name"];

  const ssNiftyData = tradeHistory && tradeHistory.data.data[ssNiftyTablename];

  // chart  data
  const { data: alpha50Data } = UseAlpha50Data();

  const alphaFiftyData = alpha50Data && alpha50Data.data.values;
  const alphaFiftyPerformceAccountValue =
    alphaFiftyData && alphaFiftyData.map((item) => [item[0], item[1], item[4]]);
  const alphaFiftyPerformceAccountValueData =
    alphaFiftyPerformceAccountValue &&
    alphaFiftyPerformceAccountValue.map((innerArray, index) =>
      innerArray.map((value, columnIndex) => {
        // Skip the first column
        if (columnIndex === 0 || typeof value !== "string") {
          return value;
        }

        // Remove the leading rupee sign and commas, then convert to a number
        return parseFloat(value.replace(/^₹|,/g, ""));
      })
    );
  // console.log("alphaFiftyData", alphaFiftyData[5][14]);
  const alpha50OutPerformance =
    alphaFiftyData && alphaFiftyData.map((item) => [item[15]]);
  const alpha50OutPerformanceValue =
    alpha50OutPerformance &&
    alpha50OutPerformance.flat().filter((value) => value !== undefined);

  // console.log("alpha50OutPerformace", alpha50OutPerformace);
  const newData =
    alphaFiftyData &&
    alphaFiftyData.map((item) => [item[0], item[10], item[11]]);
  const dataArray = newData ? newData : [];

  const alpha50MonthlyReturns = [dataArray[0]]; // Add the header to the new array

  // Create a Set to keep track of unique months
  const uniqueMonthsSet = new Set();

  // Iterate through the original array starting from index 1 (skipping the header)
  for (let i = 1; i < dataArray.length; i++) {
    const currentDate = dataArray[i][0];

    // Check if currentDate is defined and is a string
    if (currentDate && typeof currentDate === "string") {
      const [month, year] = currentDate.split("/").map(Number);
      const currentMonthKey = `${month}/${year}`; // Extract the month and year part

      // Check if the current month is not already in the uniqueMonthsSet and the data is not empty or undefined
      if (
        !uniqueMonthsSet.has(currentMonthKey) &&
        dataArray[i].every((value) => value !== undefined && value !== "")
      ) {
        alpha50MonthlyReturns.push(dataArray[i]);
        uniqueMonthsSet.add(currentMonthKey);
      }
    }
  }

  const alphaCumulative = alphaFiftyData && alphaFiftyData[1][14];
  const niftyCumulative = alphaFiftyData && alphaFiftyData[5][14];

  return (
    <div>
      {userDetails && userDetails.account_access === "admin" ? (
        <DashBoard />
      ) : (
        <div>
          <Navbar />

          <div className="mt-20">
            <div className="bg-[#14141C] flex flex-row py-8 px-12">
              <div className="px-8">
                <div className="text-2xl text-white font-montserrat">
                  Alpha 50
                </div>
                <div className="text-lg text-[#ffffff]/60 mt-2 font-poppins">
                  Unleashing the Power of Momentum Investing
                </div>
              </div>
              <div className="border-l-[1px] border-[#ffffff]/30 px-8">
                <div className="text-lg text-white font-montserrat font-semibold">
                  Investment Objective
                </div>
                <div className="text-base text-[#ffffff]/60 mt-2 font-poppins">
                  Alpha 50 is a concentrated momentum investment portfolio with
                  weekly rebalancing. Our quantitative strategy scans the top 50
                  large-cap stocks in the Nifty index and selects a portfolio of
                  10 promising stocks for your investment.
                </div>
              </div>
            </div>
            <div className={`px-4 pt-4 pb-16 flex flex-row justify-between`}>
              <div className="w-[30%] pl-4">
                {ssNiftyData && ssNiftyData ? (
                  <TableComponent watchlist={ssNiftyData && ssNiftyData} />
                ) : null}

                <div className="w-full">
                  <div className="font-montserrat border-[1px] border-b-0 border-[#ffffff]/20 bg-[#14141C]  w-[90%] text-white mt-3 py-[10px] pl-3 font-semibold">
                    Statistics
                  </div>

                  <table className={`${styles.tableUser2}`}>
                    <thead>
                      {newOverViewDataHeading &&
                        newOverViewDataHeading.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <th scope="col"></th>
                              <th className="text-right">{ele[1]}</th>
                              <th className="text-right">{ele[2]}</th>
                            </tr>
                          );
                        })}
                    </thead>

                    <tbody>
                      {newOverViewData &&
                        newOverViewData.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td className="">{ele[0]}</td>
                              <td className="text-right">{ele[1]}</td>
                              <td className="text-right">{ele[2]}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-[70%]">
                <OutPerformaceAndChart
                  monthlyReturns={
                    alpha50MonthlyReturns && alpha50MonthlyReturns
                  }
                  performaceData={
                    alphaFiftyPerformceAccountValueData &&
                    alphaFiftyPerformceAccountValueData
                  }
                  outPerformance={alpha50OutPerformanceValue}
                  titleOne="Alpha 50"
                  titleTwo="Nifty 50"
                  tabName="alpha-50"
                  alphaCumulative={alphaCumulative}
                  niftyCumulative={niftyCumulative}
                />
                <div className="w-full text-white text-2xl font-bold py-4 pl-3 font-montserrat">
                  Backtested Year-on-Year Performance
                </div>

                <table className={`${styles.tableUser1}`}>
                  <tbody>
                    <tr>
                      <th className="bg-[#14141C] font-montserrat font-semibold">
                        YEAR
                      </th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td
                              key={i}
                              className="bg-[#14141C] font-montserrat font-semibold"
                            >
                              {ele[0]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>NIFTY</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return <td key={i}>{ele[1]}</td>;
                        })}
                    </tr>
                    <tr>
                      <th>ALPHA 50</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td
                              className={
                                parseFloat(ele[2]) > parseFloat(ele[1])
                                  ? styles.greenColor
                                  : styles.redColor
                              }
                              key={i}
                            >
                              {ele[2]}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
                <table className={`${styles.tableUser3} mt-8`}>
                  <tbody>
                    <tr>
                      <th className="bg-[#14141C] font-montserrat font-semibold">
                        YEAR
                      </th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td
                              key={i + 10}
                              className="bg-[#14141C] font-montserrat font-semibold"
                            >
                              {ele[0]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>NIFTY</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return <td key={i + 10}>{ele[1]}</td>;
                        })}
                    </tr>
                    <tr>
                      <th>ALPHA 50</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td
                              className={
                                parseFloat(ele[2]) > parseFloat(ele[1])
                                  ? styles.greenColor
                                  : styles.redColor
                              }
                              key={i + 10}
                            >
                              {ele[2]}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlphaFifty;
