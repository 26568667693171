import React from "react";
import styles from "./HomePage.module.css";
const TableComponent = (props) => {
  return (
    <table className={`${styles.table}  mt-3`}>
      <thead>
        <tr>
          <th scope="col">Name of Stock</th>
          <th scope="col">RSR</th>
          <th scope="col">ID</th>
        </tr>
      </thead>
      <tbody>
        {props.watchlist &&
          props.watchlist
            .sort((a, b) => a.id - b.id)
            .map((ele, i) => {
              return (
                <tr key={i}>
                  <td className="flex flex-row items-center">
                    {ele[3] === "buy" ? (
                      <span className="h-3 w-3 rounded-full bg-[#18B13F] mr-3"></span>
                    ) : ele[3] === "sell" ? (
                      <span className="h-3 w-3 rounded-full bg-[#E7351D] mr-3"></span>
                    ) : (
                      <span className="h-3 w-3 rounded-full bg-[#EC9513] mr-3"></span>
                    )}

                    {ele[4]}
                  </td>
                  <td>{ele[2].toFixed(2)}</td>
                  <td>{ele[5].toFixed(2)}</td>
                </tr>
              );
            })}
        {props.watchlistSell &&
          props.watchlistSell
            .sort((a, b) => a.id - b.id)
            .map((ele, i) => {
              return (
                <tr key={i}>
                  <td className="flex flex-row items-center">
                    {ele[3] === "buy" ? (
                      <span className="h-3 w-3 rounded-full bg-[#18B13F] mr-3"></span>
                    ) : ele[3] === "sell" ? (
                      <span className="h-3 w-3 rounded-full bg-[#E7351D] mr-3"></span>
                    ) : (
                      <span className="h-3 w-3 rounded-full bg-[#EC9513] mr-3"></span>
                    )}

                    {ele[4]}
                  </td>
                  <td>{ele[2].toFixed(2)}</td>
                  <td>{ele[5].toFixed(2)}</td>
                </tr>
              );
            })}
      </tbody>
    </table>
  );
};

export default TableComponent;
