import React from "react";
//React Icons
import Logo from "../assets/logo.svg";
import { AiOutlineLogout } from "react-icons/ai";
import { Link, withRouter } from "react-router-dom";
import { logout } from "../store/actions/AuthActions";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect, useDispatch } from "react-redux";
import { auth } from "../firebase";
const Navbar = (props) => {
  const dispatch = useDispatch();
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
  };
  return (
    <>
      <nav
        className={`w-full flex flex-row items-center fixed top-0 shadow-sm bg-[#14141C] z-50 font-Poppins`}
      >
        {/*------------------Logo--------------------------*/}

        <a href={"/"}>
          <span className="cursor-pointer flex flex-row items-center justify-center py-2 ml-10">
            <img src={Logo} alt="Logo" width={150} />
          </span>
        </a>

        {/*------------------NAV LINK--------------------------*/}

        <div className="w-3/4 flex flex-row mr-3 justify-center items-center">
          <div
            onClick={() => props.setState("India")}
            className={
              props.state === "India"
                ? "ml-4 px-3 pt-6 pb-4 text-white border-b-2 border-[#18B13F] cursor-pointer"
                : "ml-4 px-3 pt-6 pb-4 text-neutral-300 border-b-2 border-[#14141C] cursor-pointer"
            }
          >
            INDIA
          </div>
          <div
            onClick={() => props.setState("USA")}
            className={
              props.state === "USA"
                ? "ml-4 px-3 pt-6 pb-4 text-white border-b-2 border-[#18B13F]  cursor-pointer"
                : "ml-4 px-3 pt-6 pb-4 text-neutral-300 border-b-2 border-[#14141C] cursor-pointer"
            }
          >
            USA
          </div>
        </div>
        {typeof window !== "undefined" && window.location.pathname === "/" ? (
          <div
            to="#!"
            className="flex text-white text-center items-center border-[2px] px-4 py-1 rounded-lg cursor-pointer"
            onClick={() => {
              auth.signOut();
            }}
          >
            {" "}
            <AiOutlineLogout className="mr-2" />
            Logout
          </div>
        ) : (
          <Link
            to="#!"
            className="flex text-white text-center items-center border-[2px] px-4 py-1 rounded-lg"
            onClick={onLogout}
          >
            {" "}
            <AiOutlineLogout className="mr-2" />
            Logout
          </Link>
        )}
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Navbar));
