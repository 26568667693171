import React, { useState } from "react";
import { auth, provider } from "../../firebase";
import axios from "axios";
//React Icon
import { FcGoogle } from "react-icons/fc";

import styles from "../../Login/login.module.css";
import SignUpEmail from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import SignInEmail from "./SigninEmail";

import "bootstrap/dist/css/bootstrap.min.css";

const SignIn = (props) => {
  const signIn = () => {
    auth
      .signInWithPopup(provider)
      .then((response) => {
        if (response) {
          axios
            .post("https://server.sypto.xyz/sypto/api/nifty-user", {
              email: response.additionalUserInfo.profile.email,
              name: response.additionalUserInfo.profile.name,
              firebaseId: response.additionalUserInfo.profile.id,
            })
            .then((result) => {})
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch(alert);
  };

  const [forgotPasswordState, setPasswordState] = useState(false);
  const [signUpState, setSignUpState] = useState(false);
  return (
    <div className={`${styles.mt60}`}>
      {forgotPasswordState === true ? (
        <div className={styles.loginBackground}>
          <ForgotPassword setPasswordState={setPasswordState} />
        </div>
      ) : signUpState === true ? (
        <div className={styles.loginBackground}>
          <SignUpEmail setSignUpState={setSignUpState} />
        </div>
      ) : (
        <div className={styles.loginBackground}>
          <div className={`${styles.logo} `}>Dashboard Login</div>
          <button
            onClick={() => {
              signIn();
            }}
            className="flex flex-row items-center justify-center py-2 w-full mt-2 mb-3 rounded-lg bg-white/100 text-black font-medium  ease-linear duration-150"
          >
            <div>
              <FcGoogle size={25} />
            </div>
            <div className="ml-4 select-none">Signin with Google</div>
          </button>
          <div className={styles.centerseparatorMobile}>
            <div className={styles.centerText}>or</div>
          </div>
          <SignInEmail setPasswordState={setPasswordState} />
          <div className="w-full flex justify-center mt-3 text-white border-t-[1px] pt-4">
            No account ?
            <div
              className="ml-4 select-none text-white cursor-pointer"
              onClick={() => setSignUpState(true)}
            >
              Signup with email
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignIn;
