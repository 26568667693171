import React from "react";
import moment from "moment";
import styles from "../../Home/HomePage.module.css";

const style = {
  accountHeading: "text-white text-xl pl-10 font-bold",
  tableDiv: "flex flex-wrap pl-10 pt-4",
  tableDivHeading:
    "text-white py-2 border-1 border-[#14141C]/90 text-white px-2 font-montserrat font-semibold",
  tableDivHeadingTwo:
    "text-white py-2 px-4 border-l-1 border-[#14141C]/90 text-white px-4",
};
const ShowChartTable = ({
  monthlyReturns,
  titleOne,
  titleTwo,
  alphaCumulative,
  niftyCumulative,
}) => {
  return (
    <div className="mt-6">
      <div className={styles.table_container}>
        <div className={styles.table_container}>
          <table className={`${styles.tableUser1}`}>
            <tbody>
              <tr>
                <th className={`bg-[#14141C] ${style.tableDivHeading}`}>
                  Date
                </th>
                {monthlyReturns &&
                  monthlyReturns.slice(1).map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      className={`bg-[#14141C] ${style.tableDivHeading}`}
                    >
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 0 &&
                            moment(cell, "DD/MM/YYYY").format("MMM YY")}
                        </span>
                      ))}
                    </td>
                  ))}
                <td className={`bg-[#14141C] ${style.tableDivHeading}`}>
                  Cumulative
                </td>
              </tr>
              <tr>
                <th>{titleOne}</th>
                {monthlyReturns &&
                  monthlyReturns.slice(1).map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      className={
                        row[1] &&
                        row[2] &&
                        parseFloat(row[1].replace("%", "")) >
                          parseFloat(row[2].replace("%", ""))
                          ? styles.greenColor
                          : styles.redColor
                      }
                    >
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 1 && cell}
                          {cell === " " && "N?A"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td
                  className={
                    alphaCumulative &&
                    niftyCumulative &&
                    parseFloat(alphaCumulative.replace("%", "")) >
                      parseFloat(niftyCumulative.replace("%", ""))
                      ? styles.greenColor
                      : styles.redColor
                  }
                >
                  {alphaCumulative}
                </td>
              </tr>
              <tr>
                <th>{titleTwo}</th>
                {monthlyReturns &&
                  monthlyReturns.slice(1).map((row, rowIndex) => (
                    <td key={rowIndex} className={"text-right"}>
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 2 && cell}
                          {cell === " " && "N?A"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td>{niftyCumulative}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowChartTable;
