import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import styles from "../../Login/login.module.css";
import { AiOutlineMail, AiFillLock } from "react-icons/ai";

const SignUpEmail = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [error, setError] = useState("");

  const signUpWithEmail = () => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        if (response) {
          axios
            .post("https://server.sypto.xyz/sypto/api/nifty-user/", {
              email: response.user.email,
              name: response.user.name,
              firebaseId: response.user.uid,
            })
            .then((result) => {})
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        setErrorShow(true);
        setError("The email address is already in use by another account");
      });
  };

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 8000);
    }
  }, [errorShow]);

  return (
    <div className="w-full p-4 relative font-Poppins">
      <section className="flex flex-row justify-center">
        <div
          onClick={() => props.setSignUpState(false)}
          className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-white/80 absolute -top-10 right-2 m-1 font-bold hover:text-red-600  ease-linear duration-150 cursor-pointer"
        >
          <div className="hidden md:flex ">Close</div>
          <div>
            <MdClose size={25} />
          </div>
        </div>
      </section>

      <div className="py-8">
        <div className="text-white font-Poppins md:text-2xl text-xl font-semibold pb-3">
          Signup
        </div>
        <div className={styles.loginDiv}>
          <AiOutlineMail
            style={{
              color: "#ffffff",
            }}
            className={styles.usericons}
          />
          <input
            type="email"
            className={styles.logininput}
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className={styles.loginDiv1}>
          <AiFillLock
            style={{ color: "#ffffff" }}
            className={styles.usericons}
          />
          <input
            type="password"
            className={styles.logininput}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div>
          <button
            onClick={signUpWithEmail}
            className={`flex flex-row items-center justify-center py-2 w-full mt-4 mb-3 rounded-lg bg-white/100 text-black font-medium  ease-linear duration-150`}
          >
            Signup with email
          </button>
        </div>
      </div>
      {errorShow === true ? (
        <div className="bg-white flex p-2 mt-2">
          <div className="text-[#D1433A] text-base font-bold ml-2 -mt-0.5">
            {error}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SignUpEmail;
