import React, { useState, useEffect } from "react";
import styles from "./login.module.css";
import { AiOutlineUser, AiFillLock } from "react-icons/ai";
import { connect, useDispatch } from "react-redux";
import {
  loginAction,
  loginFailedAction,
} from "../store/actions/AuthActions.js";

const Login = (props) => {
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.errorMessage != null) {
      setError(props.errorMessage);
      loginFailedAction(dispatch);
    }
  }, [props.errorMessage, dispatch]);

  // handle button click of login form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    if (!username.length || !password.length) {
      setError("UserName and password are required!");
      setLoading(false);
    } else {
      setError(null);
      await dispatch(loginAction(username, password));
      setLoading(true);
    }
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
      const toRef = setTimeout(() => {
        setLoading(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [loading]);

  return (
    <div className={`${styles.mt60}`}>
      <div className={styles.loginBackground}>
        <div className={`${styles.logo} `}>Dashboard Login</div>
        <div className={styles.loginDiv}>
          <AiOutlineUser
            style={{
              color: "#ffffff",
            }}
            className={styles.usericons}
          />
          <input
            type="text"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="username"
            className={styles.logininput}
          />
        </div>
        <div className={styles.loginDiv1}>
          <AiFillLock
            style={{ color: "#ffffff" }}
            className={styles.usericons}
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
            className={styles.logininput}
          />
        </div>

        {error ? <div className={styles.errorMsg}>{error}</div> : null}

        {loading === true ? (
          <button className={styles.loginButton}>Authenticating...</button>
        ) : (
          <button className={styles.loginButton} onClick={handleSubmit}>
            Login
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    errorMessage: state.auth.errorMessage,
  };
};

export default connect(mapStateToProps)(Login);
