import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import styles from "../../Login/login.module.css";
import { AiOutlineMail, AiFillLock } from "react-icons/ai";

const SignInEmail = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorShow, setErrorShow] = useState(false);
  const [error, setError] = useState();

  const signInWithEmail = () => {
    if (!email || !password) {
      setErrorShow(true);
      setError("Email and Password are required.");
    } else {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((response) => {
          if (response) {
            axios
              .post("https://server.sypto.xyz/sypto/api/nifty-user/", {
                email: response.user.email,
                name: response.user.name ? response.user.name : "",
                firebaseId: response.user.uid ? response.user.uid : "",
              })
              .then((result) => {})
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          setErrorShow(true);
          setError("Invalid Credentials. Please Try again");
        });
    }
  };

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 3000);
    }
  }, [errorShow]);
  return (
    <div className="max-w-screen-xl w-full relative font-Poppins">
      <div className="-mt-4">
        <div className={styles.loginDiv}>
          <AiOutlineMail
            style={{
              color: "#ffffff",
            }}
            className={styles.usericons}
          />
          <input
            type="email"
            className={styles.logininput}
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={styles.loginDiv1}>
          <AiFillLock
            style={{ color: "#ffffff" }}
            className={styles.usericons}
          />
          <input
            type="password"
            className={styles.logininput}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div
          className="ml-1 w-full flex justify-start pb-2 mt-8 text-white cursor-pointer"
          onClick={() => props.setPasswordState(true)}
        >
          Forgot your password?
        </div>

        <div>
          {" "}
          <button
            onClick={signInWithEmail}
            className="flex text-black flex-row items-center justify-center py-2 w-full mb-6 rounded-lg bg-white/100 text-white font-medium ease-linear duration-150"
          >
            <div>
              <AiOutlineMail size={25} color="#000000" />
            </div>
            <div className="ml-4 select-none text-black">Signin with email</div>
          </button>
        </div>
      </div>
      {errorShow === true ? (
        <div className="w-96 flex p-2 mt-2">
          <div className="text-[#D1433A] text-base font-semibold -mt-0.5">
            {error ? error : ""}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SignInEmail;
